import { Component, OnInit } from "@angular/core";
import { EnvService } from "gematik-form-library";

@Component({
  selector: "gem-footer",
  styleUrls: ["gem-footer.component.scss"],
  template: `
    <footer class="footer">
      <div class="row">
        <div class="col-sm-3 col-xs-12">
          <div class="footer-item">
            <div class="footer-legal-copyright text-muted">© gematik {{ currentYear }}</div>
          </div>
        </div>
        <div class="col-sm-3 col-xs-12">
          <div class="footer-item">
            <a class="footer-navigation-link text-muted" (click)="downloadTerms()">{{
              "footer.general-terms" | translate
            }}</a>
          </div>
        </div>
        <div class="col-sm-3 col-xs-12">
          <div class="footer-item">
            <a
              class="footer-navigation-link text-muted"
              href="https://www.gematik.de/datenschutz"
              target="_blank"
              >{{ "footer.privacy" | translate }}</a
            >
          </div>
        </div>
        <div class="col-sm-3 col-xs-12">
          <div class="footer-item">
            <a class="footer-navigation-link text-muted" (click)="navigateToLegalNotice()">{{
              "footer.legal-notice" | translate
            }}</a>
          </div>
        </div>
      </div>
    </footer>
  `,
})
export class GemFooterComponent implements OnInit {
  currentYear = new Date().getFullYear();

  constructor(private envService: EnvService) {}

  ngOnInit(): void {}

  navigateToLegalNotice() {
    const url: string = `${this.envService.dsdUrl}/legal-notice`;
    // const url: string = "https://www.gematik.de/impressum";
    window.open(url);
  }

  downloadTerms() {
    // const url: string = `${this.envService.dsdUrl}/assets/docs/gematik-general-terms-of-use.pdf`;
    const url: string = `${this.envService.dsdUrl}/assets/docs/Allgemeine Nutzungsbedingungen Digitaler Services v1-0 inkl VB.pdf`;
    // const url: string =
    //   "https://fachportal.gematik.de/fileadmin/Fachportal/Downloadcenter/Allgemeine_Nutzungsbedingungen_Digitaler_Services_v1-0_inkl_VB.pdf";
    window.open(url);
  }
}
